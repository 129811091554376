var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "q-mt-md" },
    [
      _c("c-upload-picture", {
        attrs: {
          height: "300px",
          attachInfo: _vm.attachInfo,
          editable: _vm.editable && !_vm.popupParam.disabled,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }